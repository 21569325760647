namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStepStatus = Umbrella.CaseFlow.CaseFlowStepStatus;
    @Pipe('CaseFlow', 'stepStatusName')
    class StepStatusNameFilter {
        public transform(input: CaseFlowStepStatus | string): string {
            if (!input) return undefined;

            switch (input) {
                case CaseFlowStepStatus[CaseFlowStepStatus.PickedUp]:
                case CaseFlowStepStatus.PickedUp:
                    return 'Opgepakt';
                case CaseFlowStepStatus[CaseFlowStepStatus.Finished]:
                case CaseFlowStepStatus.Finished:
                    return 'Afgerond';
                case CaseFlowStepStatus[CaseFlowStepStatus.Canceled]:
                case CaseFlowStepStatus.Canceled:
                    return 'Geannuleerd';
                default:
                    return '';
            }
        }
    }
}
