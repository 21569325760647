namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
    import CaseFlowStepStatus = Umbrella.CaseFlow.CaseFlowStepStatus;
    @Pipe('CaseFlow', 'isActiveStep')
    class IsActiveStepFilter {
        public transform(input: CaseFlowStep): boolean {
            if (!input) return false;

            return this.isStepActive(input);
        }

        private isStepActive(step: CaseFlowStep): boolean {
            return (
                step.status.toString() ===
                    CaseFlowStepStatus[CaseFlowStepStatus.Started] ||
                step.status.toString() ===
                    CaseFlowStepStatus[CaseFlowStepStatus.PickedUp]
            );
        }
    }
}
