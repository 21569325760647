namespace Umbrella.KCC.CaseFlowDashboard {
    import CaseFlowStep = Umbrella.CaseFlow.CaseFlowModel.CaseFlowStep;
    import CaseFlowStepStatus = Umbrella.CaseFlow.CaseFlowStepStatus;
    @Pipe('CaseFlow', 'startedStep')
    class StartedStepFilter {
        public transform(input: CaseFlowStep[]): CaseFlowStep {
            if (!input) return undefined;

            const steps = input.filter(this.isStepStarted);
            return steps.length > 0 ? steps[0] : null;
        }

        private isStepStarted(step: CaseFlowStep): boolean {
            return (
                step.status.toString() ===
                CaseFlowStepStatus[CaseFlowStepStatus.Started]
            );
        }
    }
}
